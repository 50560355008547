import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../styles/store-header.css';

function StoreHeader({ reset, isBackVisible = false }) {
  return (
    <div className="top-bar">
      {isBackVisible && (
        <div className="back-button tooltip is-tooltip-right" data-tooltip="Back To Checkout" onClick={reset}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      )}
      <div className="brand">
        <FontAwesomeIcon icon={faRocket} />
        Rocket Electronics
      </div>
      <div className="secure-lock">
        <FontAwesomeIcon icon={faLock} />
        Secure Checkout
      </div>
    </div>
  );
}

export default StoreHeader;
