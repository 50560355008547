import React, { useCallback } from 'react';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import {
  CHALLENGE_POSITIONS,
  FORTER_BACKENDS,
  EXECUTORS,
  FORTER_TOKENIZATION_ENVS,
  CARD_NETWORKS_TO_USE,
} from '../helpers/enums';
import products from '../helpers/products';
import currencies from '../helpers/currencies';

function SettingsRadio({ label, value, isChecked, name }) {
  return (
    <label className="radio custom-radio">
      <input name={name} type="radio" value={value} defaultChecked={isChecked} /> {label}
    </label>
  );
}

function Product({ type }) {
  return <option value={type}>{products[type].name}</option>;
}

function SettingsInput({ value, onChange }) {
  return <input className="input settings-input" value={value} onChange={(e) => onChange(e.target.value)} />;
}

function SettingsObjectInput({ value: keyValuePairs = [], onChange }) {
  const updateSetting = useCallback(
    (index, newKey, newValue) => {
      const newSetting = update(keyValuePairs, { [index]: { $set: [newKey, newValue] } });
      onChange(newSetting);
    },
    [keyValuePairs, onChange],
  );

  const addField = useCallback(() => {
    onChange([...keyValuePairs, ['', '']]);
  }, [keyValuePairs, onChange]);

  const removeField = useCallback(
    (index) => {
      const newSetting = update(keyValuePairs, { $splice: [[index, 1]] });
      onChange(newSetting);
    },
    [keyValuePairs, onChange],
  );

  return (
    <div className="flex">
      <button type="button" className="button is-small mr2" onClick={addField}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <div>
        {keyValuePairs.map(([aKey, aValue], index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index.toString()} className="three-ds-settings-object-section">
            <div>Key:</div>
            <input className="input" value={aKey} onChange={(e) => updateSetting(index, e.target.value, aValue)} />
            <div>Value:</div>
            <input className="input" value={aValue} onChange={(e) => updateSetting(index, aKey, e.target.value)} />
            <button type="button" className="button is-small" onClick={() => removeField(index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

function ThreeDsSettings({
  threeDsSettings: {
    challengePosition,
    enableCorrelationId,
    tokenizationMode,
    networkTokenizationMode,
    managedOrdersIntegration,
    customChallengeElement,
    disableProductSelection,
    cardNetworkToUse,
    product,
    currency,
    forterBackendEnv,
    forterTokenizationEnv,
    executor,
    headers,
    recurringPaymentCurrentPaymentNumber,
    headers: {
      proxyAuthorizationHeader,
      siteIdHeader,
      forterSubMerchantSiteIdHeader,
      authorizationHeader,
      apiVersionHeader,
      acquirerDataHeader,
      additionalInformationHeader,
      additionalIdentifiersHeader,
    },
  },
  updateThreeDsSettings,
  setChallengeDisplay,
}) {
  const onChallengePositionChanged = useCallback(
    ({ target }) => {
      updateThreeDsSettings({ challengePosition: target.value });
      setChallengeDisplay(target);
    },
    [updateThreeDsSettings, setChallengeDisplay],
  );

  const updateHeader = useCallback(
    (newValue) => {
      updateThreeDsSettings({ headers: { ...headers, ...newValue } });
    },
    [updateThreeDsSettings, headers],
  );

  return (
    <div className="overflow-auto">
      <div className="three-ds-settings-section">
        <div>Challenge Position</div>
        <div className="control settingRadioGroup" onChange={onChallengePositionChanged}>
          {Object.values(CHALLENGE_POSITIONS).map((configOption) => (
            <SettingsRadio
              name="challengePosition"
              key={configOption.value}
              isChecked={challengePosition === configOption.value}
              label={configOption.title}
              value={configOption.value}
            />
          ))}
        </div>
      </div>
      <div className="three-ds-settings-section">
        <div>Creditcard Correlation Id</div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={enableCorrelationId}
            onChange={(e) => updateThreeDsSettings({ enableCorrelationId: e.target.checked })}
          />{' '}
          Use on order
        </label>
      </div>
      <div className="three-ds-settings-section">
        <div>Managed Orders Integration</div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={!!managedOrdersIntegration}
            onChange={(e) => updateThreeDsSettings({ managedOrdersIntegration: e.target.checked })}
          />{' '}
          Enabled
        </label>
      </div>
      <div className="three-ds-settings-section">
        <div>Tokenization mode</div>
        <label className="checkbox">
          <input
            disabled={!managedOrdersIntegration}
            type="checkbox"
            checked={!!tokenizationMode}
            onChange={(e) => updateThreeDsSettings({ tokenizationMode: e.target.checked })}
          />{' '}
          Enabled
        </label>
      </div>
      <div className="three-ds-settings-section">
        <div>Provision NetworkToken</div>
        <label className="checkbox">
          <input
            disabled={!tokenizationMode}
            type="checkbox"
            checked={!!networkTokenizationMode}
            onChange={(e) => updateThreeDsSettings({ networkTokenizationMode: e.target.checked })}
          />{' '}
          Enabled
        </label>
      </div>
      <div className="three-ds-settings-section">
        <div>Custom challenge element</div>
        <label className="checkbox">
          <input
            type="checkbox"
            checked={customChallengeElement}
            onChange={(e) => updateThreeDsSettings({ customChallengeElement: e.target.checked })}
          />{' '}
          Enabled
        </label>
      </div>
      <div className="three-ds-settings-section">
        <div>Checkout Product</div>
        <div className="select products-select">
          <select
            disabled={disableProductSelection}
            defaultValue={product}
            onChange={(e) => updateThreeDsSettings({ product: e.target.value })}
          >
            {Object.keys(products).map((productType) => (
              <Product key={productType} type={productType} />
            ))}
          </select>
        </div>
      </div>
      <div className="three-ds-settings-section">
        <div>Currency</div>
        <div className="select currencies-select">
          <select
            defaultValue={currency.code}
            onChange={(e) => updateThreeDsSettings({ currency: currencies[e.target.value] })}
          >
            {Object.entries(currencies).map(([key, c]) => (
              <option name="currency" key={key} label={`${c.code} (${c.symbol})`} value={key} />
            ))}
          </select>
        </div>
      </div>
      <h1 className="three-ds-settings-section-header">Advanced settings</h1>
      <div className="three-ds-settings-section">
        <div>Forter backend environment</div>
        <div
          className="control settingRadioGroup"
          onChange={(e) => updateThreeDsSettings({ forterBackendEnv: e.target.value })}
        >
          {Object.values(FORTER_BACKENDS).map((configOption) => (
            <SettingsRadio
              name="forterBackendEnv"
              key={configOption.value}
              isChecked={forterBackendEnv === configOption.value}
              label={configOption.title}
              value={configOption.value}
            />
          ))}
        </div>
      </div>
      <div className="three-ds-settings-section">
        <div>Forter tokenization environment</div>
        <div
          className="control settingRadioGroup"
          onChange={(e) => updateThreeDsSettings({ forterTokenizationEnv: e.target.value })}
        >
          {Object.values(FORTER_TOKENIZATION_ENVS).map((configOption) => (
            <SettingsRadio
              name="forterTokenizationEnv"
              key={configOption.value}
              isChecked={forterTokenizationEnv === configOption.value}
              label={configOption.title}
              value={configOption.value}
            />
          ))}
        </div>
      </div>
      <div className="three-ds-settings-section">
        <div>Detokenization proxy site secret</div>
        <SettingsInput
          value={proxyAuthorizationHeader}
          onChange={(newValue) => updateHeader({ proxyAuthorizationHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Executor</div>
        <div
          className="control settingRadioGroup"
          onChange={(e) => updateThreeDsSettings({ executor: e.target.value })}
        >
          {Object.values(EXECUTORS).map((configOption) => (
            <SettingsRadio
              name="executor"
              key={configOption}
              isChecked={executor === configOption}
              label={configOption}
              value={configOption}
            />
          ))}
        </div>
      </div>
      <div className="three-ds-settings-section">
        <div>Site ID</div>
        <SettingsInput value={siteIdHeader} onChange={(newValue) => updateHeader({ siteIdHeader: newValue })} />
      </div>
      <div className="three-ds-settings-section">
        <div>Sub merchant site id</div>
        <SettingsInput
          value={forterSubMerchantSiteIdHeader}
          onChange={(newValue) => updateHeader({ forterSubMerchantSiteIdHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Authorization</div>
        <SettingsInput
          value={authorizationHeader}
          onChange={(newValue) => updateHeader({ authorizationHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>API Version</div>
        <SettingsInput value={apiVersionHeader} onChange={(newValue) => updateHeader({ apiVersionHeader: newValue })} />
      </div>
      <div className="three-ds-settings-section">
        <div>Acquirer data</div>
        <SettingsObjectInput
          value={acquirerDataHeader}
          onChange={(newValue) => updateHeader({ acquirerDataHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Additional Information</div>
        <SettingsObjectInput
          value={additionalInformationHeader}
          onChange={(newValue) => updateHeader({ additionalInformationHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Additional Identifiers</div>
        <SettingsObjectInput
          value={additionalIdentifiersHeader}
          onChange={(newValue) => updateHeader({ additionalIdentifiersHeader: newValue })}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Recurring Payment - current payment number</div>
        <SettingsInput
          value={recurringPaymentCurrentPaymentNumber || ''}
          onChange={(newValue) => {
            updateThreeDsSettings({ recurringPaymentCurrentPaymentNumber: parseInt(newValue, 10) });
          }}
        />
      </div>
      <div className="three-ds-settings-section">
        <div>Card Network To Use</div>
        <div
          className="control settingRadioGroup"
          onChange={({ target }) => {
            updateThreeDsSettings({ cardNetworkToUse: target.value });
          }}
        >
          {Object.values(CARD_NETWORKS_TO_USE).map(({ value, title }) => (
            <SettingsRadio
              name="cardNetworkToUse"
              key={value}
              isChecked={cardNetworkToUse === value}
              label={title}
              value={value}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default ThreeDsSettings;
