// eslint-disable-next-line import/prefer-default-export

export async function init3DS(initResponse) {
  return new Promise((resolve, reject) => {
    window.checkoutTools.tds.init3DS(
      initResponse,
      (error, threeDSID) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(threeDSID);
      },
      () => {},
    );
  });
}

export async function triggerChallengeIfNeeded(threeDSResponse, challengeContainer) {
  return new Promise((resolve, reject) => {
    const challengeEndedCallback = async (error, wasChallengePerformed, transStatus, cres) => {
      if (error) {
        reject(error);
        return;
      }

      resolve({
        wasChallengePerformed,
        transStatus,
        cres,
      });
    };
    if (challengeContainer) {
      window.checkoutTools.tds.triggerChallengeIfNeeded(threeDSResponse, challengeContainer, challengeEndedCallback);
    } else {
      window.checkoutTools.tds.triggerChallengeIfNeeded(threeDSResponse, challengeEndedCallback);
    }
  });
}
