import React from 'react';

function Notification({ notificationMessage }) {
  return (
    <div className="notification-custom notification animated fadeIn">
      <div className="notification-custom-description title">{notificationMessage}</div>
      <div className="line-scale acs-loader">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Notification;
