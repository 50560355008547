/* eslint-disable import/no-dynamic-require,global-require */
import React from 'react';
import '../styles/order-table.css';
import products from '../helpers/products';

function OrderTable({ product, currency }) {
  const { description, amount, image, name, tax, shipping } = products[product];

  // eslint-disable-next-line radix
  const getTotal = () => parseInt(amount) + parseInt(tax) + parseInt(shipping);

  return (
    <div>
      <div className="order">
        <div className="pic-and-title">
          <img className="product" src={require(`../assets/${image}.jpg`)} alt={name} />
          <div className="item-label">{description}</div>
        </div>
        <div className="prod-price">
          {currency.symbol}
          {amount}
        </div>
      </div>
      <div className="calc-description">
        <div className="desc-section">
          <div>Subtotal</div>
          <div>
            {currency.symbol}
            {amount}
          </div>
        </div>
        <div className="desc-section">
          <div>Shipping</div>
          <div>
            {currency.symbol}
            {shipping}
          </div>
        </div>
        <div className="desc-section">
          <div>Taxes</div>
          <div>
            {currency.symbol}
            {tax}
          </div>
        </div>
      </div>
      <div className="total-calc">
        <div>Total</div>
        <div>
          <small>{currency.code}</small>
          <span className="total">
            {currency.symbol}
            {getTotal()}
          </span>
        </div>
      </div>
    </div>
  );
}

export default OrderTable;
