import React from 'react';

function FormSubmit({ onCancel, isLoading, children, ...buttonProps }) {
  const loading = isLoading ? 'is-loading' : '';

  return (
    <div className="field submit-form">
      <div className="control">
        <button type="button" onClick={onCancel} className="button is-outlined is-fullwidth">
          Cancel
        </button>
      </div>
      <div className="control">
        <button type="button" className={`button is-success is-fullwidth ${loading}`} {...buttonProps}>
          Checkout
        </button>
      </div>
    </div>
  );
}

export default FormSubmit;
