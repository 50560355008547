/* eslint-disable react/button-has-type,jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid,consistent-return */
import React, { useEffect, useRef, useState } from 'react';
import '../styles/console.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faAlignJustify, faCog, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { TABS } from '../helpers/enums';
import Log from './log';
import FlowButtons from './flow-buttons';
import ThreeDsSettings from './threeds-settings';

const TAB_ICONS = {
  faAlignJustify,
  faCog,
  faLightbulb,
};

function Console({
  log,
  onResetForm,
  onClearLogPressed,
  updateThreeDsSettings,
  setChallengeDisplay,
  hideSideMenu,
  threeDsSettings,
  onFlowChange,
}) {
  const consoleDiv = useRef(null);
  let hasTooltip = '';
  const [activeTab, setActiveTab] = useState('FLOWS');
  const [showFlows, setShowFlows] = useState(true);

  useEffect(() => {
    if (consoleDiv.current) {
      consoleDiv.current.scroll(0, consoleDiv.current.scrollHeight);
    }
  });

  if (window.innerWidth >= 992) {
    hasTooltip = 'tooltip';
  }

  const renderTab = (type) => {
    const { title, icon } = TABS[type];

    return (
      <li key={type} className={`${type === activeTab ? 'is-active' : ''}`} onClick={() => setActiveTab(type)}>
        <a>
          <FontAwesomeIcon icon={TAB_ICONS[icon]} />
          <span className="tab-text">{title}</span>
        </a>
      </li>
    );
  };

  const renderFlows = () => {
    if (activeTab === 'FLOWS' || activeTab === 'ERROR_FLOWS') {
      return (
        <div className={`log-container ${showFlows ? '' : 'compress'}`}>
          <FlowButtons
            showFlows={showFlows}
            threeDsSettings={threeDsSettings}
            onFlowChange={onFlowChange}
            flowType={activeTab}
          />
          <Log
            log={log}
            showFlows={showFlows}
            onFlowChange={onFlowChange}
            onResetForm={onResetForm}
            onClearLogPressed={onClearLogPressed}
            setShowFlows={setShowFlows}
            consoleDiv={consoleDiv}
          />
        </div>
      );
    }
  };

  const renderHints = () => {
    if (activeTab === 'HINTS') {
      return (
        <div>
          <div className="hints-section">
            1. Enter your 6 digit number - <span>123456</span>
          </div>
          <div className="hints-section">
            2. What was the name of your first pet? - <span>Thomason</span>
          </div>
          <div className="hints-section">
            3. Which cities have you lived in? - <span>St Louis / Dallas</span>
          </div>
          <div className="hints-section">
            4. What was the last name of your favorite teacher? - <span>Smith</span>
          </div>
        </div>
      );
    }
  };

  const renderThreeDsSettings = () => {
    if (activeTab === 'THREE_DS') {
      return (
        <ThreeDsSettings
          updateThreeDsSettings={updateThreeDsSettings}
          setChallengeDisplay={setChallengeDisplay}
          threeDsSettings={threeDsSettings}
        />
      );
    }
  };

  return (
    <div id="drawer" className="console-quick-view">
      <div className="toolbar">
        <div className="tabs is-toggle custom-tabs">
          <ul>{Object.keys(TABS).map(renderTab)}</ul>
        </div>
        <div className="toolbar-buttons">
          <div
            className={`console-btn collapse-btn ${hasTooltip} is-tooltip-left`}
            data-tooltip="Collapse Log"
            onClick={hideSideMenu}
          >
            <FontAwesomeIcon icon={faMinus} />
          </div>
        </div>
      </div>
      {renderFlows()}
      {renderThreeDsSettings()}
      {renderHints()}
    </div>
  );
}

export default Console;
