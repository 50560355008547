import React from 'react';

const getChallengeWindow = (challengeContainer) => (
  <span id="challenge">
    <div className="bank-page-title">Bank Secured Page</div>
    <div className="bank-page-sub-title"> *Bank provided UI according to EMVco 3DS specification</div>
    <div ref={challengeContainer} className="challenge-container" id="challenge-container" />
  </span>
);

function ChallengeWindow(isRedirect, isCheckout, isModal, challengeContainer, closeModal) {
  if (isRedirect || isCheckout) {
    return getChallengeWindow(challengeContainer);
  }

  if (isModal) {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-content challenge-modal">
          <button type="button" onClick={closeModal} className="modal-close is-large" aria-label="close" />
          {getChallengeWindow(challengeContainer)}
        </div>
      </div>
    );
  }
}

export default ChallengeWindow;
