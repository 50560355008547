export default {
  ONE_PLUS: {
    name: 'OnePlus',
    description: 'OnePlus 6T 128GB 8GB Black',
    amount: '1000',
    tax: '20',
    shipping: '100',
    image: 'oneplus6t',
  },
  CHARGER: {
    name: 'Wireless Charger',
    description: 'Talentstar wireless charger for your phone',
    amount: '60',
    tax: '10',
    shipping: '10',
    image: 'wirelessCharger',
  },
  CASE: {
    name: 'Phone Case',
    description: 'Black Rhinoshield shockprof case',
    amount: '10',
    tax: '0',
    shipping: '2',
    image: 'case',
  },
};
