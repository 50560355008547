module.exports = {
  getFormInitObj: () => ({
    cc: '',
    expiry: '',
    cvv: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
  }),
};
