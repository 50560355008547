import React from 'react';

function HostedFieldContainer({ id, label }) {
  return (
    <div className="field" style={{ marginTop: 5, marginBottom: 0 }}>
      {label && (
        <label className="form-field-title" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="control input" id={id} />
    </div>
  );
}

export default HostedFieldContainer;
