import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import '../styles/thankyou.css';

function ThankYouPage({ onBackPressed }) {
  return (
    <div>
      <div className="complete-container">
        <div className="title complete-title">Thank you!</div>
        <div className="subtitle">Your order was completed successfully</div>
        <div id="continue-shopping" className="button small is-success" onClick={onBackPressed}>
          <FontAwesomeIcon className="back-to-shop-button" icon={faShoppingCart} />
          Continue Shopping
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
