import React from 'react';

function Log({ log, showFlows, onFlowChange, onResetForm, onClearLogPressed, setShowFlows, consoleDiv }) {
  return (
    <>
      <div className="log-header">
        <div className="log-title">Log</div>
        <div className="log-buttons">
          <button type="button" onClick={onFlowChange} className="button is-small util-button">
            Refill Form
          </button>
          <button type="button" onClick={onResetForm} className="button is-small util-button">
            Clear Form
          </button>
          <button type="button" onClick={onClearLogPressed} className="button is-small util-button">
            Clear Log
          </button>
          <button
            type="button"
            onClick={() => {
              setShowFlows(!showFlows);
            }}
            className="button is-small util-button"
          >
            {showFlows ? 'Expend Log' : 'Minimize Log'}
          </button>
        </div>
      </div>
      <div className="console-container" ref={consoleDiv}>
        {log.map(({ text, type }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={`log-message animated fadeIn ${type}-console`} key={index}>
            {text}
          </div>
        ))}
      </div>
    </>
  );
}

export default Log;
