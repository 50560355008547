/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import '../styles/data-entry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import cc_payment from '../assets/cc_payment.jpg';
import FormField from './form-field';
import FormSubmit from './form-submit';
import { isValidCC } from '../helpers/validation';
import { HostedCreditCardField } from './hosted-credit-card-field';
import { useForterCollect } from '../hooks/useForterCollect';
import HostedFieldContainer from './hosted-field-container';

function CreditCardFormField({ cc, cvv, expiry, onFormInputChange, disabled }) {
  return (
    <>
      <FormField
        disabled={disabled}
        onBlur={(e) => onFormInputChange(e.target)}
        value={cc}
        placeholder="Credit Card"
        onInput={(e) => onFormInputChange(e.target)}
        id="cc"
      />
      <div className="expiry_cvv">
        <div>
          <FormField disabled={disabled} value={expiry} onChange={onFormInputChange} placeholder="MM/YY" id="expiry">
            Expiry
          </FormField>
        </div>
        <div>
          <FormField disabled={disabled} value={cvv} onChange={onFormInputChange} placeholder="456" id="cvv">
            CVV
          </FormField>
        </div>
      </div>
    </>
  );
}

function DataEntryPage({
  logMessage,
  onResetForm,
  isLoading,
  formFields,
  onFormInputChange,
  onFinish,
  errorMessage,
  tokenizationMode,
  tokenizationEnv,
}) {
  const { email, firstName, lastName, address, zipCode, cc, cvv, expiry } = formFields;

  const forterCollect = useForterCollect({
    environment: tokenizationEnv,
  });

  // This component is re-mounted when tokenizationMode changes, so this effect will run again
  useEffect(() => {
    const func = async () => {
      if (forterCollect) {
        const commonStyle = {
          '::placeholder': {
            color: 'rgba(54, 54, 54, 0.3)',
            'font-size': '1rem',
            'font-family': '"system-ui", -apple-system, "Segoe UI"',
          },
        };
        await forterCollect.addFields({
          'card-holder-name': { type: 'CARD_HOLDER_NAME', placeholder: 'John doe' },
          'card-number': {
            type: 'CARD_NUMBER',
            placeholder: 'Credit Card',
            style: commonStyle,
          },
          'card-exp': { type: 'CARD_EXPIRATION_DATE', placeholder: 'MM/YY', style: commonStyle },
          'card-cvv': { type: 'CARD_CVC', placeholder: '456', style: commonStyle },
        });
      }
    };

    func();
  }, [forterCollect]);

  return (
    <div className="checkout">
      <div className="input-container">
        <div>
          {errorMessage && (
            <div className="notification error-message">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {errorMessage}
            </div>
          )}
        </div>
        <div className="shipping-info">
          <div className="shipping-info-title title">Billing Info</div>
          <div className="email">
            <FormField type="email" value={email} onChange={onFormInputChange} id="email">
              Email
            </FormField>
          </div>
          {tokenizationMode ? (
            <HostedFieldContainer id="card-holder-name" label="Full name" />
          ) : (
            <>
              <div className="first-name">
                <FormField value={firstName} onChange={onFormInputChange} id="firstName">
                  First name
                </FormField>
              </div>
              <div className="last-name">
                <FormField value={lastName} onChange={onFormInputChange} id="lastName">
                  Last name
                </FormField>
              </div>
            </>
          )}
          <div className="address">
            <FormField value={address} onChange={onFormInputChange} id="address">
              Address
            </FormField>
          </div>
          <div className="zip-code">
            <FormField value={zipCode} onChange={onFormInputChange} id="zipCode">
              Zip code
            </FormField>
          </div>
        </div>
        <div className="payment-info">
          <div className="payment-info-title title">Payment</div>
          <label className="flex items-center mb1">
            <input className="mr1" type="radio" defaultChecked />
            <img className="cc_image" src={cc_payment} alt="Available credit cards" />
          </label>
          {tokenizationMode ? (
            <>
              <HostedCreditCardField onFormInputChange={onFormInputChange} forterCollect={forterCollect} />
              <div className="checkout-button">
                <FormSubmit
                  onClick={async () => {
                    const result = await forterCollect.submit();
                    if (result.errors) {
                      const errorString = JSON.stringify(_.mapValues(result.errors, (value) => value.message));
                      logMessage(`Hosted fields validation errors:${errorString}`);
                    } else {
                      logMessage(JSON.stringify(result, null, 2));
                      if (result.success) {
                        onFinish(result.token);
                      }
                    }
                  }}
                  onCancel={onResetForm}
                  isLoading={!forterCollect}
                  id="checkout-button"
                  disabled={!forterCollect}
                />
              </div>
            </>
          ) : (
            <>
              <CreditCardFormField cc={cc} cvv={cvv} expiry={expiry} onFormInputChange={onFormInputChange} />
              <div className="checkout-button">
                <FormSubmit
                  onClick={() => onFinish()}
                  onCancel={onResetForm}
                  isLoading={isLoading}
                  id="checkout-button"
                  disabled={isLoading || !isValidCC(cc)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DataEntryPage;
