import React from 'react';

function FormField({ children, type, onChange, ...inputProps }) {
  const change = (e) => {
    if (onChange) {
      onChange(e.target);
    }
  };

  return (
    <div className="field" style={{ marginTop: 5, marginBottom: 0 }}>
      <label className="form-field-title">{children}</label>
      <div className="control">
        <input type={type || 'text'} className="input" onChange={change} {...inputProps} />
      </div>
    </div>
  );
}

export default FormField;
