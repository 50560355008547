export default {
  USD: {
    code: 'USD',
    symbol: '$',
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
  },
};
