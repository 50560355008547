import React from 'react';
import uuid from 'uuid';
import { FLOW_DATA, FLOW_TYPES, ERROR_FLOW_TYPES } from '../helpers/flows';

const FLOW_KEYS = Object.keys(FLOW_DATA);

function FlowButtons({ showFlows, onFlowChange, threeDsSettings: { flow: selectedFlow }, flowType }) {
  function renderFlow(flowKey, flow) {
    const { title, transactionStatus } = flow;
    const stateClass = selectedFlow === flowKey ? 'is-active' : `${transactionStatus}-flow`;

    return (
      <button
        type="button"
        id={flowKey}
        key={uuid()}
        onClick={() => onFlowChange(flowKey)}
        className={`button is-small flow-button ${stateClass}`}
      >
        {title}
      </button>
    );
  }

  const flowTypes = flowType === 'FLOWS' ? FLOW_TYPES : ERROR_FLOW_TYPES;

  return showFlows ? (
    <div className="flow-container">
      {flowTypes.map(([type, title]) => (
        <div key={type}>
          <div className="flow-selector-title">{title}</div>
          <div className="flow-selector">
            {FLOW_KEYS.map((key) => FLOW_DATA[key].type === type && renderFlow(key, FLOW_DATA[key]))}
          </div>
        </div>
      ))}
    </div>
  ) : null;
}

export default FlowButtons;
