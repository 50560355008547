import React from 'react';
import HostedFieldContainer from './hosted-field-container';

// eslint-disable-next-line import/prefer-default-export
export function HostedCreditCardField() {
  return (
    <>
      <HostedFieldContainer id="card-number" />
      <div className="expiry_cvv">
        <div>
          <HostedFieldContainer id="card-exp" label="Expiry" />
        </div>
        <div>
          <HostedFieldContainer id="card-cvv" label="CVV" />
        </div>
      </div>
    </>
  );
}
