const FLOW_DATA = {
  three_ds_21_approve_frictionless: {
    title: 'Approve (Frictionless)',
    cc: '5222220000000005',
    email: 'your.name@gmail.com',
    type: '3DS2.2',
    transactionStatus: 'approve',
  },
  three_ds_21_verification_required: {
    title: 'Decline Verification Required',
    cc: '5111220000000009',
    email: 'force_3ds_risk_recommendation@forter.com',
    type: '3DS2.2',
    transactionStatus: 'verification-req',
  },
  three_ds_1_approve_with_verification: {
    title: 'Approve (Frictionless)',
    cc: '2539409308331742',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'your.name@gmail.com',
    type: '3DS1',
    transactionStatus: 'approve',
  },
  three_ds_1_decline_verification_required: {
    title: 'Decline Verification Required',
    cc: '5422882800700007',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'your.name@gmail.com',
    type: '3DS1',
    transactionStatus: 'verification-req',
  },
  three_ds_1_unsupported_card: {
    title: 'Approve Unsupported Card',
    cc: '4983582387098270',
    firstName: 'Thomas',
    lastName: 'Jefferson',
    expiry: '06/24',
    currencyCode: '978',
    trackId: '1234567',
    email: 'approve@forter.com',
    type: '3DS1',
    transactionStatus: 'approve',
  },
  forter_approve: {
    title: 'Approve',
    cc: '4111111111111111',
    email: 'approve@forter.com',
    type: 'FORTER',
    transactionStatus: 'approve',
  },
  forter_decline: {
    title: 'Decline',
    cc: '4111111111111111',
    email: 'decline@forter.com',
    type: 'FORTER',
    transactionStatus: 'decline',
  },
  three_ds_21_low_value_exemption: {
    title: 'Approve Low Value Exemption',
    cc: '5222220000000006',
    email: 'force_exemption_low_value@forter.com',
    type: '3DS2.2_exemption',
    product: 'CASE',
    transactionStatus: 'approve',
  },
  three_ds_21_transaction_risk_analysis: {
    title: 'Approve Transaction Risk Analysis',
    cc: '5222220000000006',
    email: 'force_exemption_tra@forter.com',
    type: '3DS2.2_exemption',
    transactionStatus: 'approve',
  },
  three_ds_21_secure_corporate: {
    title: 'Approve Secure Corporate',
    cc: '5222220000000006',
    email: 'force_exemption_corp@forter.com',
    type: '3DS2.2_exemption',
    transactionStatus: 'approve',
  },
  three_ds_21_trusted_beneficiary: {
    title: 'Approve Trusted Beneficiary',
    cc: '5222220000000006',
    email: 'force_exemption_trusted_beneficiary@forter.com',
    type: '3DS2.2_exemption',
    transactionStatus: 'approve',
  },
  three_ds_22_exemption_over_rails: {
    title: 'Approve Transaction Risk Analysis',
    cc: '4444330090001337',
    email: 'force_exemption_tra_emvco@forter.com',
    type: '3DS2.2_exemption_rails',
    transactionStatus: 'approve',
  },
  three_ds_22_exemption_over_rails_low_value: {
    title: 'Approve Transaction Low Value',
    cc: '5112000070001338',
    email: 'force_exemption_low_value_emvco@forter.com',
    type: '3DS2.2_exemption_rails',
    transactionStatus: 'approve',
  },
  three_ds_21_anonymous_prepaid_card: {
    title: 'Approve Anonymous Prepaid Card',
    cc: '5222220000000006',
    email: 'force_exclusion_anonymous@forter.com',
    type: '3DS2.2_out_of_scope',
    transactionStatus: 'approve',
  },
  three_ds_21_merchant_initiated_transaction: {
    title: 'Approve Merchant Initiated Transaction',
    cc: '5222220000000006',
    email: 'force_exclusion_mit@forter.com',
    type: '3DS2.2_out_of_scope',
    transactionStatus: 'approve',
  },
  three_ds_21_one_leg_out_eea: {
    title: 'Approve One Leg Out EEA',
    cc: '5222220000000006',
    email: 'force_exclusion_one_leg_out@forter.com',
    type: '3DS2.2_out_of_scope',
    transactionStatus: 'approve',
  },
  three_ds_21_mail_order_telephone_order: {
    title: 'Approve Mail Order Telephone Order',
    cc: '5222220000000006',
    email: 'force_exclusion_moto@forter.com',
    type: '3DS2.2_out_of_scope',
    transactionStatus: 'approve',
  },
  not_reviewed_email: {
    title: 'Not Reviewed',
    cc: '4111111111111111',
    email: 'notreviewed@forter.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
  error_on_init: {
    title: 'Error On Init',
    cc: '4130410522210687',
    email: 'your.name@gmail.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
  error_on_3ds_flow: {
    title: 'Error In Order 3DS Flow',
    cc: '4965526389203778',
    email: 'approve@forter.com',
    type: '3DS1 Errors',
    transactionStatus: 'verification-req',
  },
  error_in_verify: {
    title: 'Error In Verify',
    cc: '4912130000000004',
    email: 'approve@forter.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
  reject_by_network: {
    title: 'Reject By Network',
    cc: '4407900000000002',
    email: 'your.name@gmail.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
  transaction_status_u_3ds1: {
    title: 'Transaction Status U',
    cc: '4234123412340006',
    email: 'your.name@gmail.com',
    type: '3DS1 Errors',
    transactionStatus: 'verification-req',
  },
  three_ds_21_challenge_request_error_approve: {
    title: 'Challenge Error (Approve)',
    cc: '5248481111200179',
    email: 'approve@forter.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
  three_ds_21_challenge_request_error_decline: {
    title: 'Challenge Error (Decline)',
    cc: '5248481111200179',
    email: 'decline@forter.com',
    type: '3DS2.2 Errors',
    transactionStatus: 'verification-req',
  },
};

const FLOW_TYPES = [
  ['3DS2.2_exemption_rails', '3DS 2.2 (EMV) - Rails Exemptions'],
  ['3DS2.2', '3DS 2.2 (EMV)'],
  ['3DS2.2_exemption', '3DS 2.2 (EMV) - Exemptions'],
  ['3DS2.2_out_of_scope', '3DS 2.2 (EMV) - Out Of Scope'],
  ['3DS1', '3DS 1.02'],
  ['FORTER', 'Forter (No 3DS)'],
];

const ERROR_FLOW_TYPES = [
  ['3DS2.2 Errors', '3DS2.2'],
  ['3DS1 Errors', '3DS 1.02'],
];

module.exports = {
  FLOW_DATA,
  FLOW_TYPES,
  ERROR_FLOW_TYPES,
};
