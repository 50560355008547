module.exports = {
  LOG_TYPES: {
    BROWSER: 'browser',
    MERCHANT: 'merchant',
    ERROR: 'error',
    PAYLOAD: 'payload',
    FORTER: 'forter',
  },
  CHALLENGE_POSITIONS: {
    AJAX_REDIRECT: { title: 'Ajax Redirect', value: 'AJAX_REDIRECT' },
    MODAL: { title: 'Modal', value: 'MODAL' },
    CHECKOUT: { title: 'Checkout', value: 'CHECKOUT' },
  },
  FORTER_BACKENDS: {
    USE: { title: 'Forter USE', value: 'USE' },
    LOCAL: { title: 'Forter local', value: 'LOCAL' },
  },
  FORTER_TOKENIZATION_ENVS: {
    PROD: { title: 'Production (PCI)', value: 'production' },
    PRODEU: { title: 'Production EU (PCI)', value: 'production-eu' },
    SANDBOX: { title: 'Sandbox (Test cards)', value: 'sandbox' },
  },
  CARD_NETWORKS_TO_USE: {
    DEFAULT: { title: 'Default', value: 'default' },
    CB: { title: 'Cartes Bancaires', value: 'CarteBancaire' },
  },
  EXECUTORS: {
    FORTER: 'FORTER',
    MERCHANT: 'MERCHANT',
  },
  NOTIFICATIONS: {
    TRIGGER_CHALLENGE: 'Sending data to trigger challenge...',
    INIT_3DS: 'Initialising 3DS...',
    SENDING_DATA_TO_ISSUER: 'Sending data to issuer...',
    TRIGGER_VERIFY: 'Verifying challenge',
  },
  TABS: {
    FLOWS: { title: 'Flows', icon: 'faAlignJustify' },
    ERROR_FLOWS: { title: 'Error Flows', icon: 'faAlignJustify' },
    THREE_DS: { title: '3DS Settings', icon: 'faCog' },
    HINTS: { title: 'Hints', icon: 'faLightbulb' },
  },
  FORTER_DECISIONS: {
    APPROVE: 'APPROVE',
    DECLINE: 'DECLINE',
    NOT_REVIEWED: 'NOT REVIEWED',
  },
  THREE_DS_VERSIONS: {
    THREE_DS_ONE: '1.0.2',
    THREE_DS_TWO: '2.1.0',
    THREE_DS_TWO_TWO: '2.2.0',
  },
};
