import qs from 'qs';
import _ from 'lodash';

const setQueryStringWithoutPageReload = (qsValue) => {
  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
};

const serializeField = (paramValue) => {
  // Query param backed settings are stored in memory as a list of key-value pairs, serialize them as a json object
  if (!_.isArray(paramValue)) {
    return paramValue;
  }

  return JSON.stringify(Object.fromEntries(paramValue.filter(([key]) => !!key)));
};

const deserializeField = (paramValue) => {
  let field;
  try {
    field = JSON.parse(paramValue);
    // Query param backed settings are stored as a json object or primitive
    // We deserialize Object as a list of key-value pairs for UI usage
    if (_.isObject(field)) {
      // Deserialize them as a list of key-value pairs
      return Object.entries(field);
    }

    return field;
  } catch (err) {
    return paramValue;
  }
};

export const updateQueryString = (settings, headers) => {
  const newValues = { settings, ...headers };
  const values = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const serializableValues = _.mapValues({ ...values, ...newValues }, serializeField);
  const compactedValues = _.pickBy(serializableValues, (x) => x !== '' && x !== '{}' && !_.isNil(x));
  setQueryStringWithoutPageReload(`?${qs.stringify(compactedValues)}`);
};

export const getQueryStringValues = () => {
  const { settings, ...headers } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return { settings: _.mapValues(settings, deserializeField), ..._.mapValues(headers, deserializeField) };
};
