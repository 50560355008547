import _ from 'lodash';
import { API_ROUTE, DEV_SERVER, HEADER_SETTINGS_TO_HEADERS } from '../settings';

const serverBaseUrl = process.env.NODE_ENV === 'development' ? `${DEV_SERVER}${API_ROUTE}` : API_ROUTE;

export default function apiCall({ method = 'post', path, body, forterBackendEnv, forterTokenizationEnv, headers }) {
  // For demo site usage
  const internalFields = { forterBackendEnv, forterTokenizationEnv };

  const headerOverrides = _.pickBy(
    headers,
    (value, key) => HEADER_SETTINGS_TO_HEADERS[key] && !_.isNil(value) && !_.isEmpty(value),
  );

  const headersWithFinalNames = _.mapKeys(headerOverrides, (_value, key) => HEADER_SETTINGS_TO_HEADERS[key]);
  const serializableHeaders = _.mapValues(headersWithFinalNames, (value) => {
    if (_.isArray(value)) {
      return JSON.stringify(Object.fromEntries(value));
    }
    return value;
  });

  const authorizationHeader = serializableHeaders.authorization;

  if (authorizationHeader && !authorizationHeader.includes('Basic')) {
    serializableHeaders.authorization = `Basic ${btoa(`${authorizationHeader}:`)}`;
  }

  return fetch(`${serverBaseUrl}/${path}`, {
    method,
    body: JSON.stringify({ ...body, ...internalFields }),
    headers: serializableHeaders,
  });
}
